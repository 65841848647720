import Button from './Button'
import PropTypes from 'prop-types'
import { FaEraser } from 'react-icons/fa';

const ButtonClear = ({onClick}) => {
  return (
    <Button 
      onClick={onClick}
      icon={< FaEraser title="Clear" />}
      className="btn btnShort"
    />
  )
}

ButtonClear.propTypes = {
  onClick: PropTypes.func
}

export default ButtonClear