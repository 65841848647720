import PropTypes from 'prop-types'

const Button = ({icon, onClick}) => {
  return (
    <button 
      onClick={onClick}
      className="btn">
      {icon}
    </button>
  )
}

Button.propTypes = {
    icon: PropTypes.object,
    onClick: PropTypes.func
}

export default Button