import Modal from "../Modal"
import { FaEraser, FaRandom } from 'react-icons/fa';

const ModalHelp = ({onClose, isOpen}) => {
  if (!isOpen) {
    return null;
  }

  const title = "How to play";
  const body = <div>
    <p>There are 5 words scrambled in a grid for you to find.</p>
    <p>Form a valid word by tapping on 1 letter in each column, from left to right, then click on the word bar at the bottom of the grid.</p>
    <p>Once you guess a valid word, those letters are removed from the game board so it pays to look ahead before guessing.</p>
    <p>Try to get as many words as you can. If there are no more valid words, the game is over.</p>
    <hr />
    <p>
      <span className="btn btnTiny btnShort">{< FaRandom title="Shuffle" />}</span>
      Click on the shuffle button to mix the letters up.
    </p>
    <p>
      <span className="btn btnTiny btnShort">{< FaEraser title="Clear" />}</span>
      Click on the eraser to clear your guess.
    </p>
  </div>
  ;
  
  return (
    <Modal key='modalHelp' title={title} body={body} onClose={onClose} isOpen={isOpen} />
  )
}

export default ModalHelp