import { useEffect, useRef, useState } from 'react';
import '../assets/css/App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Header from '../layouts/AppHeader';
import Footer from '../layouts/AppFooter';
import ButtonClear from '../components/ButtonClear';
import ButtonShuffle from '../components/ButtonShuffle';
import Grid from '../components/lima/Grid';
import Submit from '../components/lima/Submit';
import Guesses from '../components/lima/Guesses';
import * as Context from '../contexts/Lima';
import ModalHelp from '../components/lima/ModalHelp';
import ModalScore from '../components/lima/ModalScore';
import Alert from '../components/Alert';


const LimaLimaLingo = () => {
  // Refs
  const gameRef = useRef(null);

  // Game states
  const [gameId, setGameId] = useState(0);
  const [gridData, setData] = useState([]);
  const [guesses, setGuesses] = useState([]);
  const [word, setWord] = useState('     ');
  const [isOver, setGameOver] = useState(false);
  const [history, setHistory] = useState();

  // UI states
  const [helpOpen, setHelpOpen] = useState(false);
  const [scoreOpen, setScoreOpen] = useState(false);
//  const [settingOpen, setSettingOpen] = useState(false);
  const [gameAlert, setGameAlert] = useState('');

  const onKeyDown = (e) => {
    if (isOver) {
      return;
    }
    
    switch (e.key) {
      case "Escape":
        // Close modal if open
        // Clear submit button
        onClear();
        break;
      case "Enter":
        // Submit if game not over
        onSubmit();
        break;
      case "Backspace":
        // Remove last letter from word
        const newData = Context.removeLetter(gridData.slice());
        setData(newData);
        setWord(Context.getWord(newData));
        setFocus();
      break;
      default:
        if (Context.isAlpha(e.key)) {
          const newData = Context.addLetter(gridData.slice(), e.key);
          setData(newData);
          setWord(Context.getWord(newData));
          setFocus();
        }
        break;
    }
  };

  useEffect(() => {
    fetchData();

    // Cleanup
    return () => {
    };
  }, []);

  const setFocus = () => {
    gameRef.current.focus();
  }

  // Fetch game data
  const fetchData = () => {
    const data = Context.fetchGameData();
    const grid = data.gridData;
    setData(grid);
    setWord(Context.getWord(grid));
    setGuesses(data.guesses);
    setGameOver(data.isOver);
    setHistory(data.history);
    setGameId(data.gameId);

    if (data.history.totalGames === 1 && data.guesses.length === 0) {
      setHelpOpen(true);
    }

    gameRef.current.focus();
  };

  const onColumnClick = (column) => {
    setGameAlert('');
    let newData = [];
    for (let i = 0; i < gridData.length; i++) {
      if (gridData[i].id === column.id) {
        newData.push(column);
      }
      else {
        newData.push(gridData[i]);
      }
    }
    setData(newData);
    setWord(Context.getWord(gridData));
    setFocus();
  };

  const onSubmit = () => {
    // Validate word
    console.log(word);
    if (!Context.isValidWord(word)) {
      displayAlert('Nope');
      setFocus();
      return;
    }

    // Move letters into guesses
    let newGuesses = guesses.slice();
    newGuesses.push({word: word, isCorrect: true});
    setGuesses(newGuesses);

    const newGridData = Context.removeActiveWords(gridData);
    setData(newGridData);
    clearWord();

    const isGameOver = Context.isGameOver(newGridData);
    Context.save(newGridData, newGuesses, gameId, isGameOver, history);
    setGameOver(isGameOver);
    if (isGameOver) {
      onScore();
      // Set time over here
    }
    setFocus();
  }

  const onShuffle = () => {
    setData(Context.randomizeColumns(gridData.slice()));
    setFocus();
  }

  const onClear = () => {
    setData(Context.setLettersInactive(gridData.slice()));
    clearWord();
    setFocus();
  }

  const clearWord = () => {
    setWord('     ');
  }

  const onHelp = (e) => {
    setHelpOpen(true);
  }

  const onScore = (e) => {
    setScoreOpen(true);
  }
/*
  const onSetting = (e) => {
    setSettingOpen(true);
  }
*/
  const onModalClose = (e) => {
    setHelpOpen(false);
//    setSettingOpen(false);
    setScoreOpen(false);
    setFocus();
  }

  const displayAlert = (text) => {
    setGameAlert(text);
    setTimeout(() => {
      setGameAlert('');
    }, 4000);
  }

  let showAlert
  if (gameAlert) {
    showAlert = <Alert text={gameAlert} />
  }
  else {
    showAlert = null;
  }

  let modal = null;
  if (helpOpen) {
    modal = <ModalHelp onClose={onModalClose} isOpen={helpOpen} />
  }
/*
  if (settingOpen) {
    modal = <ModalSetting onClose={onModalClose} isOpen={settingOpen} />
  }
*/
  if (scoreOpen) {
    const tomorrow = Context.getNextDate(gameId); // TODO Need to set this when game is over
    const onNewGame = () => {
      fetchData();
      onModalClose();
    }

    const onGameOver = () => {
      setGameOver(true);
      onModalClose();
    }

    const onRetry = () => {
      const newData = Context.retryGame();
      const grid = newData.gridData;
      setData(grid);
      setWord(Context.getWord(grid));
      setGuesses(newData.guesses);
      setGameOver(newData.isOver);
      setHistory(newData.history);
      setGameId(newData.gameId);
      onModalClose();
    }

    modal = <ModalScore onClose={onModalClose} isOpen={scoreOpen} 
      history={history} 
      isGameOver={isOver} 
      gameId={gameId} 
      nextGameTime={tomorrow}
      onNewGame={onNewGame}
      onRetry={onRetry}
      onGameOver={onGameOver}
      displayAlert={displayAlert}
    />
  }

  return (
    <div ref={gameRef} tabIndex='0' onKeyDown={onKeyDown}>
      <Header title='Lima Lima Lingo' onHelp={onHelp} onScore={onScore} />
      <div className="container">
        <Guesses guesses={guesses} />
        <Grid key='gameGrid' gridData={gridData} onClick={onColumnClick} isOver={isOver} />
        <div className='actionBar'>
          <ButtonShuffle className='btn btnShort' onClick={onShuffle} />
          <Submit word={word} onClick={onSubmit} />
          <ButtonClear className='btn btnShort' onClick={onClear} />
        </div>
      </div>
      <Footer />
      {modal}
      {showAlert}
    </div>
  );
}

export default LimaLimaLingo;