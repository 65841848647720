import PropTypes from 'prop-types'
import '../assets/css/Alert.css';

const Alert = ({text}) => {
  const className = 'alert ' + (text.length > 4 ? 'alert-long' : '');
  return (
    <div className={className}>
      {text}
    </div>
  )
}

Alert.propTypes = {
  text: PropTypes.string,
}

export default Alert