import PropTypes from 'prop-types'
import ButtonHelp from "../components/ButtonHelp"
import ButtonScore from "../components/ButtonScore"
//import ButtonSetting from "../components/ButtonSetting"
import '../assets/css/AppHeader.css';

const AppHeader = ({title, onHelp, onScore, onSetting}) => {
  return (
    <div className="App-header">
      <div className='header-container'>
        <header className="header">
            <h1>
              {title}
            </h1>
            <ButtonScore onClick={onScore} />
            <ButtonHelp onClick={onHelp} />
        </header>
      </div>
    </div>
  )
}

AppHeader.propTypes = {
  title: PropTypes.string,
  onScore: PropTypes.func,
  onHelp: PropTypes.func
}

export default AppHeader