function fallbackCopyClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let success = false;
  try {
    success = document.execCommand('copy', false, text);
  } 
  catch (err) {
  }

  document.body.removeChild(textArea);

  if (!success) {
    if (window.clipboardData) {
      try {
        window.clipboardData.setData("Text", text);
      } 
      catch (err) {
      }
    }    
  }
  return success;
}

export async function copyToClipboard(text) {
  if (!navigator.clipboard) {
    return fallbackCopyClipboard(text);
  }
  return await navigator.clipboard.writeText(text).then(() => {
    return true;
  }, (err) => {
    return false;
  });
}

export async function share(title, text, url) {
  const message = `${title}\n${text}\n${url}`;
  if (!navigator.canShare) {
    return await copyToClipboard(message).then(() => {
      return true;
    }, (err) => {
      return false;
    });
  }
  try {
    const shareData = {
      title: title,
      text: message
    }
    return await navigator.share(shareData).then(() => {
      return true;
    }, (err) => {
      return false;
    });
  }
  catch(e) {}
  return false;
}
