import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import './assets/css/App.css';


import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import HomePage from './pages/HomePage';
import LimaLimaLingo from './pages/LimaLimaLingo';
import NotFound from './pages/NotFound';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <NotFound />,
    children: [

    ]
  },
  {
    path: "/limalimalingo",
    element: <LimaLimaLingo />,
    errorElement: <NotFound />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="App">
    <RouterProvider router={router} />
  </div>
);
