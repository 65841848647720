import Column from "./Column"

const Grid = ({gridData, isOver, onClick}) => {
  return (
    <div className={"grid " + (isOver ? "grid-disabled" : "")}>
      {gridData.map((data, index) => (
        <Column
          key={data.id}
          column={data}
          onColumnClick={onClick}
          isOver={isOver} />
      ))}
    </div>
  );
}

Grid.defaultProps = {
  gridData: [],
  isOver: true
}

export default Grid