import { Link } from 'react-router-dom';
import brainyxLogo from '../assets/img/logo_dark.svg';

const AppFooter = () => {
  return (
    <footer className="App-footer">
      <a href='/' className='noLine'><img src={brainyxLogo} alt='brainyx.com' className='logo' /></a><br />
      Copyright &copy; <Link to='/'>brainyx.com</Link> 2022 
    </footer>
)
}

export default AppFooter