import PropTypes from 'prop-types'
import Guess from './Guess'

const Guesses = ({guesses}) => {
  if (!guesses.length) {
    return null;
  }

  return (
    <div key='guessContainer' className='guessContainer'>
      {guesses.map((guess, index) => (
        <Guess key={"guess" + index} index={index} word={guess.word} isCorrect={guess.isCorrect} />
      ))}
    </div>
  )
}

Guesses.defaultProps = {
  guesses: []
}

Guesses.propTypes = {
  guesses: PropTypes.array
}

export default Guesses