import Games from '../data/lima/games.json';
import Words from '../data/lima/words.json';
import { DateTime } from 'luxon';

const WORD_SIZE = 5;
const WORD_COUNT = 5;
const COOKIE_NAME = 'limalimalingo';
const START_DATE = DateTime.fromObject({year: 2022, month: 10, day: 23});

const _defaultGameHistory = {
  scores: [0, 0, 0, 0, 0, 0],
  current: 0,
  totalGames: 1
};
const _defaultGameData = {
  gridData: [],
  guesses: [],
  gameId: 0,
  isOver: false,
  history: _defaultGameHistory
};

export function fetchGameData()
{
  console.log('fetchGameWords');
  // get game ID from cookie
  // if gameData available from cookie, use it, otherwise create a new game
  let gameData = restore();
  let history = gameData.history;
  if (!history || !history.scores) {
    history = getNewHistory();
  }

  // Check game ID is current
  const gameId = getGameId();
  if (!isValidGame(gameData)) {
    gameData = getNewGame();
  }
  gameData.history = history;

  if (gameData.isOver && gameData.gameId !== gameId) {
    // Previous game is over. Create a new game
    gameData = Object.assign({}, gameData, getNewGame());
    // Copy current game into history
    gameData.history = history;
    if (gameData.history.current) {
      gameData.history.scores[gameData.history.current]++;
    }
    gameData.history.current = 0;
    gameData.history.totalGames++;
  }

  return gameData;
}

export function retryGame()
{
  let gameData = fetchGameData();
  const history = gameData.history;
  gameData = Object.assign({}, gameData, getNewGame());
  gameData.isOver = false;
  gameData.history = history;
  gameData.history.current = 0;
  return gameData;
}

const getNewGame = () => {
  const data = [];
  const gameId = getGameId();
  if (gameId > Games.length) {
    // We've run out of games, randomly create data from words
    for (let i = 0; i < 5; i++) {
      const index = Math.floor(Math.random() * Words.length);
      data.push(Words[index]);
    }
  }
  else {
    // Use pre-scrambled words
    const words = Games[gameId];
    words.forEach((word) => {
      data.push(word);
    });
  }
  let gameData = {
    gridData: initializeData(data),
    gameId: gameId
  }
 
  // Set defaults
  return Object.assign({}, _defaultGameData, gameData);
}

const isValidGame = (gameData) => {
  if (!gameData || !gameData.gridData || gameData.gridData.length !== WORD_SIZE) {
    return false;
  }

  const wordCount = (gameData.gridData[0].letters ?? []).length + gameData.guesses.length;
  return wordCount === WORD_COUNT;
}

const getGameId = () => {
  const currentDate = DateTime.now();
  return Math.floor(currentDate.diff(START_DATE, 'days').as('days'));
}

export const getNextDate = (gameId) => {
  return START_DATE.plus({days: gameId + 1});
}

const initializeData = (data) => {
  console.log('initializeData');
  const gridData = [
    { id: 'col0', letters: []}, 
    { id: 'col1', letters: []}, 
    { id: 'col2', letters: []}, 
    { id: 'col3', letters: []}, 
    { id: 'col4', letters: []}, 
  ];
  for (let i = 0; i < data.length; i++) {
    let word = data[i];
    if (word.length !== WORD_SIZE) {
      continue;
    }
    for (let j = 0; j < WORD_SIZE; j++) {
      gridData[j].letters.push({
        id: 'letter' + ((i * WORD_SIZE) + j),
        letter: word[j],
        isActive: false
      });
    }
  };
  return gridData;
};

export function isValidWord(word)
{
  return Words.includes(word);
}

export function isAlpha(str)
{
  return str.length === 1 && /^[a-zA-Z]+$/.test(str);  
}

export function addLetter(gridData, character)
{
  if (!character) {
    return;
  }
  
  character = character.trim().toUpperCase();
  
  // Try to find character in respective column
  const column = getInactiveColumn(gridData);
  setActiveLetter(column, character);
  
  return gridData;
}

function getInactiveColumn(gridData)
{
  for (let i = 0; i < gridData.length; i++) {
    const column = gridData[i];
    let hasActive = false;
    column.letters.forEach((letter) => {
      if (letter.isActive) {
        hasActive = true;
        return;
      }
    });
    if (!hasActive) {
      return column;
    }
  }
  return null;
}

function setActiveLetter(column, character)
{
  if (!column || !character) {
    return;
  }

  for (let i = 0; i < column.letters.length; i++) {
    const letter = column.letters[i];
    if (letter.letter === character) {
      letter.isActive = true;
      return;
    }
  }
}

export function removeLetter(gridData)
{
  for (let i = gridData.length - 1; i >= 0; i--) {
    const column = gridData[i];
    let hasActive = false;
    column.letters.forEach((letter) => {
      if (letter.isActive) {
        hasActive = true;
        letter.isActive = false;
        return;
      }
    });
    if (hasActive) {
      return gridData;
    }
  }
  return gridData;
}

export function getNewHistory()
{
  return _defaultGameHistory;
}

//#region Save and Restore
function updateHistory(guesses, history)
{
  let currentCount = 0;
  guesses.forEach((guess) => {
    if (guess.isCorrect) {
      currentCount++;
    }
  });

  history.current = currentCount;
  return history;
}

export function save(gridData, guesses, gameId, isOver, history)
{
  history = updateHistory(guesses, history);
  const gameData = {
    gridData: gridData ?? [],
    guesses: guesses ?? [],
    gameId: gameId ?? 0,
    isOver: isOver,
    history: history ?? {}
  }
  
  const expiryDate = new Date();
	expiryDate.setDate(expiryDate.getDate() + 365);
  document.cookie = COOKIE_NAME + '=' + JSON.stringify(gameData) + ';expires=' + expiryDate.toUTCString() + ';path=/; SameSite=Strict';  
}

function restore()
{
	const cookies = document.cookie.split(';');
  let json = {};

	// Loop through array of cookies
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i].trim();
		if (cookie.indexOf(COOKIE_NAME + "=") === 0) {
			// Cookie found, return value
			json = cookie.substring(COOKIE_NAME.length + 1, cookie.length);
      break;
		}
	}

  if (json) {
    try {
      json = JSON.parse(json);
    }
    catch(exp) {
      json = {};
    }
  }
  return json;
}
//#endregion

//#region Column methods
export function sortColumns(gridData) 
{
  for (let i = 0; i < gridData.length; i++) {
    let letters = gridData[i].letters;
    letters.sort((a, b) => {
      if ( a.letter < b.letter ){
        return -1;
      }
      if ( a.letter > b.letter ){
        return 1;
      }
      return 0;
    });
    gridData[i].letters = letters;
  }
}

export function randomizeColumns(gridData) 
{
  for (let i = 0; i < gridData.length; i++) {
    let letters = gridData[i].letters;
    letters = letters
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    gridData[i].letters = letters;
  }
  
  resetLetterIds(gridData);
  return gridData;
}

export function resetLetterIds(gridData) 
{
  const wordSize = gridData.length;
  for (let i = 0; i < wordSize; i++) {
    let column = gridData[i];
    for (let j = 0; j < column.letters.length; j++) {
      column.letters[j].id = 'letter' + ((i * wordSize) + j);
    }
  }
}

export function setLettersInactive(gridData)
{
  gridData.forEach(column => {
    column.letters.forEach(letter => {
      letter.isActive = false;
    })
  });
  return gridData;
}

export function getWord(gridData)
{
  let word = '';
  gridData.forEach(column => {
    const activeLetter = column.letters.find(letter => {
      return letter.isActive;
    });
    word += activeLetter ? activeLetter.letter : ' ';
  });
  return word;
}

export function removeActiveWords(gridData)
{
  let newData = [];
  for (let i = 0; i < gridData.length; i++) {
    const column = gridData[i];
    const letters = column.letters.filter(letter => {
      return !letter.isActive;
    }) 
    let newColumn = { id: column.id, letters: letters};
    newData.push(newColumn);
  }
  return newData;
}

export function isGameOver(gridData)
{
  // Test to see there are any valid words left in the grid
  if (!gridData.length || !gridData[0].letters.length) {
    return true;
  }

  const rowCount = gridData[0].letters.length;
  // All 5 word combos will have 5 solutions
  if (rowCount === 5) {
    return false;
  }

  // Itirate through each row and try to make valid words from the available data
  for (let a = 0; a < rowCount; a++) {
    for (let b = 0; b < rowCount; b++) {
      for (let c = 0; c < rowCount; c++) {
        for (let d = 0; d < rowCount; d++) {
          for (let e = 0; e < rowCount; e++) {
            const word = gridData[0].letters[a].letter + gridData[1].letters[b].letter + gridData[2].letters[c].letter + gridData[3].letters[d].letter + gridData[4].letters[e].letter;
            if (isValidWord(word)) {
              return false;
            }
          }
        }
      }
    }
  }
  return true;
}
//#endregion
