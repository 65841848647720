import PropTypes from 'prop-types'
import '../../assets/css/lima/Guess.css';

const Guess = ({index, word, isCorrect}) => {
  if (!word.length) {
    return null;
  }
  
  let charItems = [];
  for (let i = 0; i < word.length; i++) {
    charItems.push(
      <div key={"char" + i}>{word[i]}</div>
    );
  }

  return (
    <div key={"guess" + index} className={"guess " + (isCorrect ? "" : "incorrect")}>
      {charItems}
    </div>
  )
}

Guess.propTypes = {
  index: PropTypes.number,
  word: PropTypes.string,
  isCorrect: PropTypes.bool
}

export default Guess