import { useRouteError, Link } from "react-router-dom";

import Footer from '../layouts/Footer';
import Header from '../layouts/Header';

const NotFound = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div id='NotFound'>
      <Header />
      <div className="App-body">
        <h1>Nothing to see here</h1>

        <p>How about a nice game of <Link to='/limalimalingo'>Lima Lima Lingo</Link>?</p>
      </div>
      <Footer />
    </div>
  );
}
export default NotFound;