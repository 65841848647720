import {Link} from "react-router-dom";

import Footer from '../layouts/Footer';
import Header from '../layouts/Header';

import LogoLima from '../assets/img/logo_limalimalingo.png'

const HomePage = () => {
  return (
    <div id='HomePage'>
      <Header />
      <div className="App-body container">
        <h1>Games</h1>

        <dl>
          <dt>
            <Link to="/limalimalingo" className="noLine">
              <img src={LogoLima} alt='Lima Lima Lingo logo' />
            </Link>
            <br/>
            <Link to="/limalimalingo">
              Lima Lima Lingo
            </Link>
          </dt>
          <dd>
            A daily game of <strong>five</strong> words, scrambled over <strong>five</strong> columns
            with 200 milliion combinations in every game.
            Try your best to find them all.<br />
          </dd>
        </dl>
      </div>
      <Footer />
    </div>
  );
}
export default HomePage;