import PropTypes from 'prop-types'

const Submit = ({word, onClick}) => {
  let disabled = word.includes(" ") || word.length !== 5;
  let letters = word.split('').map((letter, index) =>
    <b key={"guess" + index}>{letter}</b>
  );


  return (
    <button 
      key='btnSubmit'
      className='btn btnSubmit btnFlex'
      disabled={disabled}
      onClick={onClick}
    >
      {letters}
    </button>
  )
}

Submit.propTypes = {
  word: PropTypes.string,
  onClick: PropTypes.func
}

Submit.defaultProps = {
  word: ''
}

export default Submit