import '../../assets/css/lima/Letter.css';

const Letter = ({letter, onLetterClick}) => {
  const onClick = (e) => {
    onLetterClick(letter);
    e.target.blur();
  }

  let classActive = 'letter';
  if (letter.isActive) {
    classActive += ' active';
  }

  return (
    <button key={letter.index} onClick={onClick} className={classActive}>
      {letter.letter}
    </button>
  )
}

export default Letter