import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Modal as MuiModal } from '@mui/material';
import '../assets/css/Modal.css'
import { FaTimes } from 'react-icons/fa';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Modal = ({title, body, onClose, isOpen}) => {
  return (
    <MuiModal
    open={isOpen}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
      <Box sx={style} className='modal-container'>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div>{title}</div>
          <button onClick={onClose} title='Close'><FaTimes /></button>
        </Typography>
        <Typography id="modal-modal-description" component="div" sx={{ mt: 1 }}>
          {body}
        </Typography>
      </Box>      
    </MuiModal>
  )
}

export default Modal