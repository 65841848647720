import { useEffect, useState, useCallback } from 'react';
import { FaShareAlt, FaBackward } from 'react-icons/fa';

import Modal from "../Modal"
import Typography from '@mui/material/Typography';
import '../../assets/css/lima/ModalScore.css';
import * as TextHelper from "../../helpers/textHelper";
import { DateTime } from 'luxon';


const timers = {
  game: null
};

const ModalScore = ({onClose, isOpen, isGameOver, history, gameId, nextGameTime, onNewGame, onRetry, displayAlert, onGameOver}) => {
  // UI states
  const [buttonText, setButtonText] = useState('');
  const NEW_GAME = 'New Game';

  const checkNewGameTimer = useCallback(() => {
    if (isGameOver) {
      const currentTime = DateTime.now();
      if (nextGameTime > currentTime) {
        timers.game = setTimeout(checkNewGameTimer, 1000);
        const timeDifference = nextGameTime.diff(currentTime, ['hours', 'minutes', 'seconds']).toFormat('hh:mm:ss');
        setButtonText(timeDifference);
      }
      else {
        setButtonText(NEW_GAME);
      }
    }
  }, [isGameOver, nextGameTime])

  useEffect(() => {
    checkNewGameTimer();

    return () => {
      clearTimeout(timers.game);
    }
  }, [checkNewGameTimer]);

  if (!isOpen) {
    return null;
  }

  const newGameClick = () => {
    onNewGame();
  }

  let continueSection = null;
  if (buttonText) {
    const disabled = buttonText !== NEW_GAME;
    continueSection = <div>
      <hr />
      <div className="center">
        New game starts
        {disabled ? ' in' : ' now'}
      </div>
      <div className="actionBar">
        <button className='btn btnSubmit' disabled={disabled} onClick={newGameClick}>{buttonText}</button>
      </div>
    </div>
  }
  else {
    continueSection = <div>
      <hr />
      <div className="actionBar">
        <button className='btn' onClick={onGameOver}>Resign Game</button>
      </div>
    </div>
  }

  const title = "Score";

  let totalScore = 0;
  let scores = [0, 0, 0, 0, 0];
  let totalGames = 1;
  let current = 0;

  if (history) {
    current = history.current;
    totalScore = current;
    totalGames = history.totalGames;

    scores = [...history.scores];
    scores.forEach((score, index) => totalScore += score * index);
    scores[current] += 1;
    scores = scores.filter((score, index) => index > 0);
  }
  let average = totalGames > 0 ?  Math.floor(totalScore / totalGames) : 0;

  const shareClick = async () => {
    const title = `#LimaLimaLingo #${gameId} ${current}/5`;
    const text = `${'🟨'.repeat(current) + '⬛'.repeat(5-current)}`;
    const url = 'https://www.brainyx.com/limalimalingo';
    let success = false;
    await TextHelper.share(title, text, url).then(response => {
      success = response;
    }).catch(response => {
      success = false;
    });
    if (success) {
      displayAlert("Shared");
    }
    else {
      displayAlert("Failed to share");
    }
  }

  const shareSection = <div className="actionBar marginTop">
      <button className='btn btnReset' onClick={onRetry}>
      <FaBackward /> Retry
      </button>
      <button className='btn btnSubmit' onClick={shareClick}>
        Share <FaShareAlt />
      </button>
     </div>;
  

  const body = 
  <div className="modalScore">
    {isGameOver ? <Typography component="h2">GAME OVER</Typography> : null}
    <div className="summary">
      <div>
        <Typography component="span" variant="h4">
          {totalScore}
        </Typography>
        <Typography component="span" variant="subtitle2">
          word{totalScore !== 1 ? 's' : ''} found
        </Typography>
      </div>
      <div>
        <Typography component="span" variant="h4">
          {average}
        </Typography>
        <Typography component="span" variant="subtitle2">
          average
        </Typography>
      </div>
      <div>
        <Typography component="span" variant="h4">
          {totalGames}
        </Typography>
        <Typography component="span" variant="subtitle2">
          game{totalGames !== 1 ? 's' : ''} played
        </Typography>
      </div>
      <div>
        <Typography component="span" variant="h4">
          {gameId + 1}
        </Typography>
        <Typography component="span" variant="subtitle2">
          current game id
        </Typography>
      </div>
    </div>
    <hr />
    <ul className="score">
      {scores.map((score, index) => {
          return <li key={'score' + index} className={(index + 1) === current ? "current" : ""}>
            <div className="key">{index + 1}</div>
            <div className="value" style={score > 0 ? {} : {visibility:'hidden'}}><div style={{width: (totalGames ? Math.floor((score * 100) / totalGames) : 0) + '%'}}>{score > 0 ? score : ''}</div></div>
          </li>
      })}
    </ul>
    <p className="center"><a href='https://www.reddit.com/r/limalimalingo/'>View archived games</a></p>
    {shareSection}
    {continueSection}
  </div>
  
  
  return (
    <Modal key='modalScore' title={title} body={body} onClose={onClose} isOpen={isOpen} />
  )
}

export default ModalScore
