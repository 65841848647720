import Button from './Button'
import PropTypes from 'prop-types'
import { FaRegChartBar } from 'react-icons/fa'

const ButtonScore = ({onClick}) => {
  return (
    <Button 
      onClick={onClick}
      icon={< FaRegChartBar title="Score" />}
      className="btn"
    />
  )
}

ButtonScore.propTypes = {
    onClick: PropTypes.func
}

export default ButtonScore