import Button from './Button'
import PropTypes from 'prop-types'
import { FaRandom } from 'react-icons/fa';

const ButtonShuffle = ({onClick}) => {
  return (
    <Button 
      onClick={onClick}
      icon={< FaRandom title="Shuffle" />}
      className="btn btnShort"
    />
  )
}

ButtonShuffle.propTypes = {
  onClick: PropTypes.func
}

export default ButtonShuffle