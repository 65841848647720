import PropTypes from 'prop-types'
import Letter from './Letter'
import '../../assets/css/lima/Column.css';

const Column = ({column, isOver, onColumnClick}) => {
  const onClick = (evtLetter) => {
    if (isOver) {
      return;
    }

    // Reset all letter states in this column
    const wasActive = evtLetter.isActive;
    column.letters.forEach(letter => {
      letter.isActive = false;
    });
    if (!wasActive) {
      evtLetter.isActive = true;
    }
    onColumnClick(column);
  }

  return (
    <div className='column'>
      {column.letters.map((data) => (
        <Letter 
          key={data.id}
          letter={data}
          onLetterClick={onClick} />
      ))}
    </div>
  )
}

Column.propTypes = {
  letters: PropTypes.arrayOf(PropTypes.string),
  position: PropTypes.number
}

export default Column