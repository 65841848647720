import Button from './Button'
import PropTypes from 'prop-types'
import { FaRegQuestionCircle } from 'react-icons/fa'

const ButtonHelp = ({onClick}) => {
  return (
    <Button 
      onClick={onClick}
      icon={< FaRegQuestionCircle title="Help" />}
      className="btn"
    />
  )
}

ButtonHelp.propTypes = {
    onClick: PropTypes.func
}

export default ButtonHelp